import { ReactElement, ReactNode, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAccount } from 'accounts/actions';
import fetchAccounts from 'data/account/actions/fetchAccounts';
import { createSubscription } from 'reactivate/actions/createSubscription';
import { fetchTrialStatus } from 'reactivate/actions/fetchTrialStatus';
import { getAuthAccount } from 'shared/auth';
import isLoggedIn from 'shared/auth/selectors/isLoggedIn';
import useQuery from 'shared/hooks/useQuery';
import Layout from 'shared/layouts/ReactivateLayout';
import request from 'shared/request';
import { RootState } from 'store';
import { exitApp } from 'store/actions/app';
import Button from 'wiw/ui/Button';
import LoadingApp from 'wiw/ui/loading/LoadingApp';

import { Map } from 'immutable';
import Cookies from 'js-cookie';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';

import 'reactivate/assets/css/login.scss';

type ReactivateLayout30Props = {
  location: ReactElement,
  rest: any,
  component: any
  pageName: string
  computedMatch: {
    path: string
  }
}

export default function ReactivateLayout30({ component: Component, location, pageName, computedMatch, ...rest }: ReactivateLayout30Props) {

  const [accountIneligible, setAccountIneligible] = useState(false);

  const authAccount = useSelector(getAuthAccount);
  const loggedIn = useSelector(isLoggedIn);
  const trialStatus = useSelector((store:RootState) => store.trial.get('status'));

  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory<ReactNode>();

  // convert route to a unique class name i.e /accounts/:id/something -> accounts-something dropping any params
  const routeName = computedMatch.path.split('/').filter(it => it.length && !it.startsWith(':')).join('-');

  useEffect(() => {
    if (authAccount) {
      dispatch(fetchTrialStatus())
        .catch(() => {
          // could not fetch trial status so we cannot do anything with them
          setAccountIneligible(true);
        });
    }
  }, [authAccount]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchAccounts())
        .then(response => {
          let user = {};
          if (response.users.length === 1) {
            // use the only user record for this person
            user = response.users[0];
          } else if (response.users.length > 1) {
            // use the user record associated with the intended account_id
            user = response.users.find(user => user.account_id == query.get('account_id'));
          }

          if (user) {
            const account = response.accounts.find(account => account.id === user?.account_id);
            if (account) {
              dispatch(selectAccount(user, account, null, false));
            } else {
              setAccountIneligible(true);
            }
          } else {
            // a user could not be auto-selected so we cannot do anything with them
            setAccountIneligible(true);
          }
        });
    }
  }, [loggedIn]);

  useEffect(() => {
    if (trialStatus?.status !== null && authAccount) {
      routeUser();
    }
  }, [trialStatus, authAccount]);

  const routeUser = () => {
    if (trialStatus?.isActive() || trialStatus?.isUnavailable()) {
      setAccountIneligible(true);
    }

    if (trialStatus?.isAvailable()) {
      dispatch(createSubscription(false))
        .then(res => {
          const metadata = {
            thirtyDayFreeTrial: JSON.stringify({
              startedTrialDate: DateTime.local().startOf('day').toISO(),
              expiresDate: res.expiresDate,
              sawFinalDialog: false,
            }),
          };

          return request('platform')
            .put('/meta/account', new Map(metadata).map(value => JSON.stringify(value)).toJSON());
        })
        .then(() => {
          setLoginMessageCookie();
          dispatch(exitApp(`${CONFIG.FRONTEND_URL}/scheduler`));
        })
        .catch(() => {
          setAccountIneligible(true);
        });
    }
  };

  const setLoginMessageCookie = () => {
    Cookies.set('login-message', 'reactivate30', { expires: 7, domain: CONFIG.COOKIE_DOMAIN });
  };

  const toScheduler = () => {
    return dispatch(exitApp('/scheduler'));
  };

  return (
    <Layout
      title="Try everything FREE for 30 days!"
      copy="Build the work schedule in minutes and instantly share it with your team. During your free trial,
      you’ll be able to focus on your most important tasks, your employees will appreciate more flexible scheduling,
      and your business will have a competitive edge when it comes to hiring great people."
      pageName={ pageName }
      routeName={ routeName }
    >
      <Suspense fallback={
        <div className="reactivate-loading">
          <LoadingApp />
        </div>
      }>
        { accountIneligible ? (
          <div className="row reactivate-content login-pane">
            <div className="col">
              <h3>Your account isn't eligible for the trial.</h3>
              <p>You still have everything you need to build the schedule and instantly share it with your team!</p>
              <Button color="gold" onClick={ toScheduler }>Go to Scheduler</Button>
            </div>
          </div>
        ) : (
          <Component { ...rest } history={ history } location={ location } />
        )}
      </Suspense>
    </Layout>
  );
}
