import request from 'shared/request';

import { camelizeKeys, decamelizeKeys } from 'humps';
import Immutable from 'immutable';
import Cookies from 'js-cookie';
import { deburr, isNull, omitBy } from 'lodash';
import QueryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

class Account extends Immutable.Record({
  id: null,
  type: null,
  company: '',
  logo: null,
  place: {},
  accountHolder: null,
  subdomain: '',
  registered: null,
  industryId: null,
  planId: null,
  updatedAt: null,
  createdAt: null,
  timezoneId: null,
  settings: null,
  referralCode: null,
  refPage: null,
  refPlanId: null,
  refStatus: null,
  refEmployees: null,
  utmSource: null,
  utmMedium: null,
  utmTerm: null,
  utmContent: null,
  utmCampaign: null,
  mercuryUserAttributionId: null,
  userCount: null,
  masterId: null,
  subscriptions: [],
  billingType: null,
  convertedAt: null,
  attendanceFreemium: null,
  isDeleted: null,
  isDeactivated: null,
  planCustom: null,
  planExpires: null,
  trialCreatedAt: null,
  wbExpires: null,
  masterPlanId: null,
  trialLength: null,
  phoneNumber: null,
  motivations: null,
  signupOrigin: null,
}) {
  constructor(defaults) {
    super(camelizeKeys(defaults));
  }

  isParent() {
    return this.masterId > 0 && this.id === this.masterId;
  }

  isChild() {
    return this.masterId > 0 && this.id !== this.masterId;
  }

  toSnake() {
    return decamelizeKeys(this.toJS());
  }

  getName() {
    return this.company || this.subdomain;
  }

  sanitizedName() {
    return deburr(this.getName());
  }

  isActive() {
    return !(this.isDeleted || this.isDeactivated || !this.enabled);
  }

  typeName() {
    const accountTypes = {
      1: 'Scheduling',
      2: 'Attendance',
      3: 'Both',
    };

    return accountTypes[this.type];
  }

  static list(pending = true) {
    let url;
    if (pending) {
      url = '/login?show_pending=true';
    } else {
      url = '/login';
    }

    return request('api').get(url, {});
  }

  static search(query, placeIds = []) {
    const queryString = QueryString.stringify({
      q: query,
      places: true,
    });
    let url = `/login/find?${ queryString }`;
    if (placeIds.length) {
      url += `&${  placeIds.map(pid => `place_ids[]=${ pid }`).join('&')}`;
    }
    return request('api').get(url, {});
  }

  static register(id) {
    return request('api').post('/login/register', {
      account_id: id,
    });
  }

  static create(account) {
    account = account.merge({
      referralCode: Cookies.get('referral_code') || null,
      utmSource: Cookies.get('utm_source') || null,
      utmMedium: Cookies.get('utm_medium') || null,
      utmTerm: Cookies.get('utm_term') || null,
      utmContent: Cookies.get('utm_content') || null,
      utmCampaign: Cookies.get('utm_campaign') || null,
      mercuryUserAttributionId: Cookies.get('user_attribution_id') || uuidv4(),
    });

    return request('api').post('/account', {
      ...omitBy(account.toSnake(), isNull),
      allow_duplicate_place: true,
    });
  }

  static update(account) {
    return request('api').put(`/account/${account.id}`, {
      ...omitBy(account.toSnake(), isNull),
    });
  }

  updatePlan(payload) {
    return request('api').post('/billing/plan', payload);
  }
}

export default Account;
